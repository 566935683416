
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarFinanceReports from "@/components/sidebar-finance-reports.vue"; // @ is an alias to /src
import ReportService from "@/services/reports-service";
import DashboardPDFmake from "@/components/dashboard-pdfmake.vue"


@Component({
  components: {
    Header,
    SidebarFinanceReports,
    DashboardPDFmake
  },
})
export default class FinanceReports extends Vue {

  total_sales = 0;
  total_vat = 0;
  highest_sales = 0;
  highest_sales_id = '';
  highest_sales_po: any = {};
  selected_report_date_from: any = '';
  selected_report_date_to: any = '';

  total_invoices = 0;
  mounted() {
    this.retrieve();

  }

  getMonthName(month: any) {
    const d = new Date();
    d.setMonth(month - 1);
    const monthName = d.toLocaleString("default", { month: "long" });
    return monthName;
  }

  retrieve() {
    ReportService.getstatictics()
      .then((response) => {
        let master_data = response.data
        this.total_invoices = master_data['total_invoices'].toLocaleString()
        let projectsales = master_data['project_wise_sum']
        let monthsales = master_data['month_wise_sum']
        let vatsales = master_data['vat_wise_sum']

        // monthsales.forEach((x:any) => {x.month = this.getMonthName(x.month)})
        // let month = monthsales.map((x:any)=> x.month)
        // let data_monthly = monthsales.map((x:any)=> x.sum)

        // vatsales.forEach((x:any) => {x.month = this.getMonthName(x.month)})
        // let month_vat = vatsales.map((x:any)=> x.month)
        // let data_monthly_vat = vatsales.map((x:any)=> x.sum)

        this.projectExpenses(projectsales)
        this.projectRevenue(projectsales)
        this.projectPerSale(projectsales)
        this.projectPerSaleper(projectsales)

        this.monthlySale([], [])
        this.revenuemonthlySale([], [])
        this.salarymonthlySale([], [])
        this.monthlyVat([], [])

      })
      .catch((e) => {
        console.log(e);
      });
  }
  monthlySaleSeries: any = [];
  monthlySaleChartOptions: any = {};
  revenuemonthlySaleSeries: any = [];
  revenuemonthlySaleChartOptions: any = {};
  salarymonthlySaleSeries: any = [];
  salarymonthlySaleChartOptions: any = {};
  monthlyVatSeries: any = [];
  monthlyVatChartOptions: any = {};
  projectExpensesSeries: any = [];
  projectExpensesChartOptions: any = {};
  projectRevenueSeries: any = [];
  projectRevenueChartOptions: any = {};
  projectPerSaleSeries: any = [];
  projectPerSaleChartOptions: any = {};
  projectPerSaleperSeries: any = [];
  projectPerSaleperChartOptions: any = {};
  monthlySale(data: any, category: any) {
    this.monthlySaleSeries = [{
      name: 'Expenses Per Month',
      // colorByPoint: true, 
      data: [37.8, 790.34, 100, 121.5],
      color: '#1e3c79',
      showInLegend: false

    }]
    this.monthlySaleChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      title: {
        text: 'Monthly Inflation in Argentina, 2002',
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444'
        }
      }
    }
  }

  revenuemonthlySale(data: any, category: any) {
    this.revenuemonthlySaleSeries = [{
      name: 'Expenses Per Month',
      // colorByPoint: true, 
      data: [37.8, 790.34, 100, 121.5],
      color: '#1e3c79',
      showInLegend: false

    }]
    this.revenuemonthlySaleChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  salarymonthlySale(data: any, category: any) {
    this.salarymonthlySaleSeries = [{
      name: 'Revenue Per Month',
      // colorByPoint: true, 
      data: [37.8, 790.34, 100, 121.5],
      color: '#1e3c79',
      showInLegend: false
    }]
    this.salarymonthlySaleChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  monthlyVat(data: any, category: any) {
    this.monthlyVatSeries = [{
      name: 'Revenue Per Month',
      // colorByPoint: true, 
      data: [37.8, 790.34, 100, 121.5],
      color: '#1e3c79',
      showInLegend: false
    }]
    this.monthlyVatChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  projectExpenses(data: any) {
    this.projectExpensesSeries = [{
      name: 'Revenue Per Month',
      // colorByPoint: true, 
      data: [37.8, 790.34, 100, 121.5],
      color: '#1e3c79',
      showInLegend: false
    }]
    this.projectExpensesChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }

  projectRevenue(data: any) {
    this.projectRevenueSeries = [{
      name: 'Revenue Per Month',
      // colorByPoint: true, 
      data: [37.8, 790.34, 100, 121.5],
      color: '#1e3c79',
      showInLegend: false
    }]
    this.projectRevenueChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }

  projectPerSale(data: any) {
    this.projectPerSaleSeries = [44, 55, 41, 17, 15],
      this.projectPerSaleChartOptions = {
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function (val: any, opts: any) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
          }
        },
        // title: {
        //   text: 'Gradient Donut with custom Start-angle'
        // },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
  }

  projectPerSaleper(data: any) {
    this.projectPerSaleperSeries = [44, 55, 41, 17, 15],
      this.projectPerSaleperChartOptions = {
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function (val: any, opts: any) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
          }
        },
        // title: {
        //   text: 'Gradient Donut with custom Start-angle'
        // },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
  }

  clear() {
    this.selected_report_date_from = '';
    this.selected_report_date_to = '';
  }
  print() {
    let route: any = null;
    route = this.$router.resolve({ path: this.highest_sales_id });
    window.open(route.href);
  }
}
